<template>
    <div class="box_2 flex-row">
        <div class="image-wrapper_3 flex-col">
            <img class="image_2" referrerpolicy="no-referrer" :src="$url(formData.logo)" />
        </div>
        <span class="text_28">{{ formData.siteName }}</span>
        <div class="label_2"></div>
    </div>
</template>
  
<script>
export default {
    name: 'websiteCard',
    props: {
        formData: {},
    },
    methods: {
    }
}
</script>
<style scoped lang="css" src="./assets/index.rem.css" />