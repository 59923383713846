<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="section_13 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云名片</span>
        <span class="text_4">展示企业信息&nbsp;业务人员信息</span>
        <div class="box_17 flex-row justify-between" @click="$scrollToTop('content')" style="cursor: pointer;">
          <div class="image-wrapper_1 flex-col">
            <img class="thumbnail_1" referrerpolicy="no-referrer"
              src="./assets/img/ps92jnr42vn7pd091p2vb47v4rnd3yz1f9c4cdf4c0-f2ef-4310-8fac-490d6c15abcc.png" />
          </div>
          <span class="text_5">立即探索</span>
        </div>
      </div>
      <div id="content" class="section_14 flex-col">
        <div class="text-group_52 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;Business&nbsp;Card</span>
          <span class="text_7">云名片&nbsp;让你的联系方式更便捷</span>
        </div>
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            在当今社会，人们的社交圈越来越广泛，我们需要不断地结识新朋友、拓展人脉。而作为一个职场人士，更是需要时刻保持联系畅通，因此名片也成为了职场必备的工具之一。
            <br />
            然而，传统的纸质名片却存在很多不方便之处。比如，你可能会经常忘记携带名片，或者常常因为名片被弄丢而感到心烦。此外，传统名片也难以编辑和更新，这意味着你需要重新印制新的名片才能更新联系方式，这样的成本也不容忽视。
            <br />
          </span>
          <span class="text_8">为了解决这些问题，云名片应运而生。</span>
          <span class="text_9">
            可以通过网络储存和分享，用户可以轻松地将自己的名片信息存储在云端，并通过二维码或链接与他人分享，避免了传统纸质名片的很多不便之处。
          </span>
        </div>
        <div class="box_18 flex-col">
          <div class="group_17 flex-row">
            <div class="text-group_53 flex-col justify-between">
              <span class="text_10">Main&nbsp;advantages</span>
              <span class="text_11">主要优势</span>
            </div>
          </div>
          <div class="text-wrapper_19 flex-row">
            <span class="text_12">与传统的纸质名片相比，云名片的优点在于：</span>
          </div>
          <div class="group_18 flex-row">
            <img class="image_8" referrerpolicy="no-referrer"
              src="./assets/img/ps16b9ai8bsuf79tt66kowh8aw184exgfx694596905-30ce-4bf4-b1bc-ef119c270394.png" />
            <div class="text-wrapper_20 flex-col justify-between">
              <span class="text_13">更加环保</span>
              <span class="text_14">云名片无需使用纸张，支持移动端在线使用，降低企业和个人的碳排放和资源浪费。</span>
            </div>
            <img class="image_9" referrerpolicy="no-referrer"
              src="./assets/img/ps41zhubl5rl3a217wc0wv5mcvcu5uy39e1ed6aff5-a65e-452c-ad22-a3d7ccd8d724.png" />
            <div class="text-wrapper_21 flex-col justify-between">
              <span class="text_15">更加便捷</span>
              <span class="paragraph_2">
                用户只需要扫描二维码或者点击链接即可获取到名片信息，避免了携带纸质名片的麻烦。此外，云名片还可以随时编辑和更新，不再需要频繁更换名片。
                <br />
              </span>
            </div>
          </div>
          <div class="group_19 flex-row">
            <img class="image_10" referrerpolicy="no-referrer"
              src="./assets/img/psvhdn41tgaralecz4sxoomrwbtunz74ovbee3fe56-8442-446c-a0e8-9a9765e58d89.png" />
            <div class="text-wrapper_22 flex-col justify-between">
              <span class="text_16">更加专业</span>
              <span class="text_17">
                云名片是一款专为电机行业打造的数字名片，它采用智能化的设计和高效化的功能，帮助电机企业和从业者实现在线展示、轻松交流和有效联系，提高品牌曝光度和商业影响力。为电机行业构建全面数字化的生态圈，让企业和个人更好地适应未来的发展趋势和挑战。
              </span>
            </div>
            <img class="image_11" referrerpolicy="no-referrer"
              src="./assets/img/psdkperx0mhj9lkmp1ob15tfnt30hf029bea256efaf-2b1e-44d9-9902-170a6bea3fe3.png" />
            <div class="text-wrapper_23 flex-col justify-between">
              <span class="text_18">更加智能</span>
              <span class="text_19">
                云名片中包含地图定位信息，用户仅需轻轻一点，便可以自动打开导航软件，并导航到名片中包含的地址信息，无需再次输入操作，方便快捷。
              </span>
            </div>
          </div>
        </div>
        <img class="image_12" referrerpolicy="no-referrer"
          src="./assets/img/psjk43pp2w0z14p8oip5ifwqsdewjjo5290cf6b3d-5212-4b93-992b-862f34b63043.png" />
      </div>
      <div class="block_4 flex-col">
        <div class="section_1 flex-col">
          <div class="section_15 flex-row">
            <el-tabs v-model="query.category" @tab-click="loadData(true)">
              <el-tab-pane label="最新" name="最新"></el-tab-pane>
              <el-tab-pane label="同城" name="同城"></el-tab-pane>
            </el-tabs>
            <div class="group_7 flex-col">
              <el-row :gutter="1">
                <el-col :span="20">
                  <el-input v-model="query.keywords" style="width:100%" placeholder="请输入关键字" clearable
                    @change="loadData(true)"></el-input>
                </el-col>
                <el-col :span="4">
                  <el-button type="primary" size="medium" icon="el-icon-search" @click="loadData(true)"></el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div v-if="list.length > 0" class="group_20 flex-col">
          <el-row :gutter="10">
            <el-col v-for="(item, index) in list" :key="index" :span="8">
              <card :form-data="item"></card>
            </el-col>
          </el-row>
        </div>
        <div v-else class="group_20 flex-row">
          <div style="width:100%;height:200px;text-align: center;font-size: medium;color:darkgray;margin-top:100px;"> 暂无数据
          </div>
        </div>
        <bottom-detector :loadingMore="loading" :noMore="list.length >= total" @arriveBottom="loadData" :distance="400" />
        <div class="group_29 flex-row">
          <div class="text-group_66 flex-col justify-between">
            <span class="text_72">云名片</span>
            <span class="text_73">展示企业信息&nbsp;业务人员信息</span>
          </div>
        </div>
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
import card from "./card.vue"
import api from './api.js'
import bottomDetector from '@/views/components/bottomDetector/index.vue'
export default {
  components: {
    headerCom,
    fotter,
    card,
    bottomDetector
  },
  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      query: {
        // 三取一
        category: "最新",
        keywords: "",
        total: 0,
        size: 9,
        pn: 1,
      },
      constants: {}
    };
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(flg) {
      this.loading = true
      api.list(this.query).then((res) => {
        if (res.code === 200) {
          if (flg) {
            this.list = res.data.list
            this.total = res.data.total
            this.query.pn = 1
          } else {
            this.list = [...this.list, ...res.data.list]
            this.total = res.data.total
            this.query.pn++;
          }
          this.loading = false

        }
      })
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />