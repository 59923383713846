import 'vant/lib/index.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {
  Button,
  DropdownMenu,
  DropdownItem,
  Cell,
  Switch,
  Field
} from 'vant'
import {
  RadioGroup,
  Radio
} from 'vant';
import {
  CheckboxGroup,
  Checkbox
} from 'vant';
import {
  Tab,
  Tabs
} from 'vant';
import {
  Tabbar,
  TabbarItem
} from 'vant';
import {
  NavBar,
  Icon,
  Tag
} from 'vant';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueScrollTo from 'vue-scrollto'

Vue.component('van-field', Field)
Vue.component('van-button', Button)
Vue.component('van-dropdown-menu', DropdownMenu)
Vue.component('van-dropdown-item', DropdownItem)
Vue.component('van-cell', Cell)
Vue.component('van-switch', Switch)
Vue.component('van-checkbox-group', CheckboxGroup)
Vue.component('van-checkbox', Checkbox)
Vue.component('van-radio-group', RadioGroup)
Vue.component('van-radio', Radio)
Vue.component('van-tab', Tab)
Vue.component('van-tabs', Tabs)
Vue.component('van-tabbar', Tabbar)
Vue.component('van-tabbar-item', TabbarItem)
Vue.component('van-nav-bar', NavBar)
Vue.component('van-icon', Icon)
Vue.component('van-tag', Tag)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueScrollTo)

function scrollToTop(id) {
  const element = document.getElementById(id);
  const rect = element.getBoundingClientRect();
  const top = rect.top + window.pageYOffset;
  window.scrollTo({
    top: top,
    behavior: 'smooth'
  });
}

function url(url) {
  if (url && url.length && url.includes('http')) {
    // 如果已经有 http 或 https 前缀，直接返回原始字符串
    return url;
  } else {
    // 否则添加 http 前缀并返回
    return url ? 'https://www.motor-e.com.cn/source/' + url : '';
  }
}

Vue.prototype.$scrollToTop = scrollToTop
Vue.prototype.$url = url

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')