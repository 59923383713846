<template>
  <div class="section_4 flex-row justify-between">
    <div class="box_2 flex-col">
      <span class="text_3">关于我们</span>
      <div class="text-wrapper_1">
        <span class="text_4">
          绿色电机系统云平台是一个集成了项目设计、企业运营管理、云商城和云服务等多个功能模块的
        </span>
        <span class="text_5">全流程电机系统解决方案的综合性平台。</span>
      </div>
      <span class="text_6">
        平台针对电机系统企业运营管理提供了全面的云服务，如云工厂、云官网、标识解析、云金融等方面，可以帮助企业节省管理成本，提高生产效率和质量。同时，通过云集采、云商城和云物流等服务，可以打造行业闭环产业链，形成完整的设计、采购、生产、销售、售后等链条，更好地服务于客户的需求。
      </span>
      <div class="image-text_1 flex-row justify-between">
        <a href="/lanhu_guanyuwomen">
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/psmr30tzbb13gbqtvj8u1nb4ailxcybtnc8d6d7fab-b659-4b1e-8188-2565c214985d.png"
        />
      </a>
        <span class="text-group_1"><a href="/lanhu_guanyuwomen">立即探索</a></span>
      </div>
    </div>
    <img
      class="image_2"
      referrerpolicy="no-referrer"
      src="./assets/img/aboutus.png"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />