<template>
  <div class="block_17 flex-col">
    <div class="group_82 flex-row justify-between">
      <img :src="$url(formData.avatarUrl)" class="group_46 flex-col" />
      <div class="text-wrapper_62 flex-col justify-between">
        <span class="text_94">{{ formData.userName }}</span>
        <span class="text_95">{{ formData.createTime }}</span>
      </div>
    </div>
    <div class="text-wrapper_63 flex-row">
      <span class="text_96">
        {{ formData.content }}
      </span>
    </div>
    <div v-if="formData.pictures.length > 0" class="group_83 flex-row">
      <div class="list_13 flex-row">
        <!-- <div
          class="list-items_1 flex-col"
          v-for="(item, index) in formData.pictures"
          :key="index"
        >
          <img :src="$url(item.url)" />
                 :preview-src-list="formData.pictures"
        </div> -->
        <div class="list-items_1 flex-col">
          <el-image
            style="width: 200px; height: 200px"
            :src="$url(formData.pictures[0].url)"
            :preview-src-list="getUrlList(formData.pictures)"
          >
          </el-image>
        </div>
        <div
          class="list-items_1 flex-col"
          v-if="formData.pictures && formData.pictures.length >= 2"
        >
          <el-image
            style="width: 200px; height: 200px"
            :src="$url(formData.pictures[1].url)"
            :preview-src-list="getUrlList(formData.pictures)"
          >
          </el-image>
        </div>
        <div
          class="list-items_1 flex-col"
          v-if="formData.pictures && formData.pictures.length >= 3"
        >
          <el-image
            style="width: 200px; height: 200px"
            :src="$url(formData.pictures[2].url)"
            :preview-src-list="getUrlList(formData.pictures)"
          >
          </el-image>
        </div>

        <div
          class="list-items_1 flex-col"
          v-if="formData.pictures && formData.pictures.length == 4"
        >
          <el-image
            style="width: 200px; height: 200px"
            :src="$url(formData.pictures[3].url)"
            :preview-src-list="getUrlList(formData.pictures)"
          >
          </el-image>
          <span
            v-if="formData.pictures && formData.pictures.length >= 4"
            style="
              position: absolute;
              background: gray;
              width: 1.68rem;
              color: white;
              opacity: 0.7;
              font-size: 0.8rem;
            "
          >
            更多
          </span>
        </div>

        <!-- <div
          class="list-items_1 flex-col"
          v-if="formData.pictures && formData.pictures.length >= 5"
        >
          <el-image
            style="width: 200px; height: 200px"
            :src="$url(formData.pictures[4].url)"
            :preview-src-list="getUrlList(formData.pictures)"
          >
          </el-image>
        </div> -->
      </div>
    </div>
    <div class="group_84 flex-row">
      <div class="image-text_35 flex-row justify-between">
        <img
          class="thumbnail_41"
          referrerpolicy="no-referrer"
          src="./assets/img/psfgonpsaj0l2qlzsskgkl6125w23o6hqmpc98dbc6f-4d04-4a0b-9415-46ba219bf1a9.png"
        />
        <span class="text-group_23">{{ formData.location }}</span>
      </div>
      <img
        class="image_33"
        referrerpolicy="no-referrer"
        src="./assets/img/ps2xsue30za6cf15ou25ajsvz5cebemo36mb882b582-736c-45c3-aacb-8b290631c662.png"
      />
      <span class="text_97">{{ formData.hitsCount }}</span>
      <img
        class="thumbnail_42"
        referrerpolicy="no-referrer"
        src="./assets/img/psdcydhzg92aqp1t5bjofmc9j58ejt9xd12064bd14-1db8-478b-a724-2390459bf11d.png"
      />
      <span class="text_98">{{ formData.commentsCount }}</span>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "supplyCard",
  props: {
    formData: {},
  },
  methods: {
    getThreePictures: function (array) {
      return array.slice(0, 3);
    },
    getUrlList: function (array) {
      console.log("getUrlList");
      var temp = array.map((item) => this.$url(item.url));
      return temp;
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />