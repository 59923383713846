<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="group_2 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云创新联合体</span>
        <span class="text_4">产业集群凸显集约优势和集约效益</span>
        <div class="box_3 flex-row justify-between" @click="$scrollToTop('content')" style="cursor: pointer;">
          <div class="image-wrapper_1 flex-col">
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/psjrr0h4x309ab8leru5ocyafbw91h0s5s4839cb3a2-b3d1-478d-a318-79c2731320a4.png"
            />
          </div>
          <span class="text_5">立即探索</span>
        </div>
      </div>
      <div id="content" class="group_3 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;Innovation&nbsp;Consortium</span>
          <span class="text_7">云创新联合体</span>
        </div>
        <div class="section_1 flex-row justify-between">
          <div class="text-wrapper_1">
            <span class="text_8">云创新联合体是绿色电机平台为业内精英提供的</span>
            <span class="text_9">一个自主发展的平台</span>
            <span class="paragraph_1">
              。
              <br />
            </span>
            <span class="paragraph_2">
              任何的业内精英都可以申请一个属于自己的创新联合体。&nbsp;云创新联合体不仅仅是一个整合资源的平台，也是一个自我宣传和展示的平台，企业主可以申请一个联合体，发布自己的产品信息、供求信息。
              <br />
              同时，创建者可以将联合体中的信息推送至绿色电机平台，也可以将绿色电机平台的信息接入到联合体中。
            </span>
          </div>
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/ps1mit5ons4ctwnuohp8hwb80fzqtx508ke3a21c6e-fe4e-4c96-9951-8e47b975d70d.png"
          />
        </div>
        <div class="section_2 flex-col">
          <span class="text_10">WHY?</span>
          <span class="text_11">为什么要建云创新联合体？</span>
          <div class="text-wrapper_2 flex-row justify-between">
            <span class="text_12">01</span>
            <span class="text_13">我们提供的是资源整合的平台，自我宣传和整合的平台</span>
          </div>
          <div class="text-wrapper_3 flex-row justify-between">
            <span class="text_14">02</span>
            <span class="text_15">资源整合,打造属于自己的工业生态圈</span>
          </div>
          <div class="text-wrapper_4 flex-row justify-between">
            <span class="text_16">03</span>
            <span class="text_17">推广的媒介，企业建立联合体，可对自己公司进行推广</span>
          </div>
        </div>
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter
  },
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />