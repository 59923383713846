<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="section_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云官网</span>
        <span class="text_4">智能动态官网展示企业核心优势</span>
        <div class="group_3 flex-row justify-between" @click="$scrollToTop('content')" style="cursor: pointer;">
          <div class="image-wrapper_1 flex-col">
            <img class="thumbnail_1" referrerpolicy="no-referrer"
              src="./assets/img/psunmpcjenjnbtms4tohx2hxsgdj6ykl9jebd32076-49a1-4982-b5e8-7466fb24bf67.png" />
          </div>
          <span class="text_5">立即探索</span>
        </div>
      </div>
      <div id="content" class="section_3 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;official&nbsp;website</span>
          <span class="text_7">云官网</span>
        </div>
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            云官网是绿色电机行业数字化转型的重要平台。
            <br />
          </span>
          <span class="text_8">
            在这里，您可以获取、传递最新的行业资讯和技术动态，了解最新的行业动态，并参与到数字化转型的浪潮中来。们的使命是为电机行业提供更高效、更智能、更可持续的解决方案，帮助企业实现数字化转型，迎接未来挑战。
          </span>
        </div>
        <div class="block_2 flex-row">
          <div class="text-wrapper_2 flex-col"><span class="text_9">特点一</span></div>
          <div class="text-wrapper_3 flex-col justify-between">
            <span class="text_10">最新的行业资讯和技术动态</span>
            <span class="text_11">
              在云官网上，您可以获取最新的行业资讯和技术动态，包括绿色电机平台的最新进展、电机行业的发展趋势、技术创新和应用案例等信息。通过云官网，您可以充分了解电机行业的最新动态，获得更多的商机和机遇。
            </span>
          </div>
        </div>
        <div class="block_3 flex-row">
          <div class="text-wrapper_4 flex-col"><span class="text_12">特点二</span></div>
          <div class="text-group_2 flex-col justify-between">
            <span class="text_13">在线的数字化转型工具</span>
            <span class="paragraph_2">
              云官网也提供了在线的数字化转型工具。通过这些工具，用户可以自主完成数字化转型的流程设计、数据采集、分析处理等工作，并可以根据自身需求进行模块化的定制，实现数字化转型的智能化、高效化。这些工具包括数据采集器、应用模板和自定义开发框架等。
              <br />
            </span>
          </div>
        </div>
        <div class="block_4 flex-row">
          <div class="text-wrapper_5 flex-col"><span class="text_14">特点三</span></div>
          <div class="text-wrapper_6 flex-col justify-between">
            <span class="text_15">技术支持和服务</span>
            <span class="text_16">
              云官网还提供了专业的技术支持和服务。无论您在数字化转型过程中遇到什么问题或疑虑，都可以随时联系我们的客服团队，获取专业的帮助和支持。我们的技术团队会为您提供一对一的指导和咨询服务，确保您的数字化转型过程顺利进行。
            </span>
          </div>
        </div>
        <div class="block_5 flex-row">
          <div class="text-wrapper_4 flex-col">
            <span class="text_12">特点四</span>
          </div>
          <div class="text-wrapper_8 flex-col justify-between">
            <span class="text_19">多元化的交流平台</span>
            <span class="text_20">
              在云官网上，用户可以加入到在线社区中，与其他电机产业从业者和爱好者进行交流，分享经验、讨论技术问题，并参加各种形式的活动和培训课程，获得更多的合作机会和发展空间。
            </span>
          </div>
        </div>
        <div class="block_6 flex-row">
          <div class="box_1 flex-row">
            <div class="text-wrapper_5 flex-col justify-between">
              <span class="text_14">特点五</span>
            </div>
          </div>
          <div class="text-wrapper_9 flex-col justify-between">
            <span class="text_23">灵活多变的信息展示</span>
            <span class="text_24">
              除了已经介绍的特点外，云官网还为企业提供了灵活多变的信息展示方式，包括文字、图片、视频等形式，方便企业更好的展示自身产品信息和设备信息的优势，进一步增加企业展示的吸引力。
            </span>
          </div>
        </div>
      </div>
      <div class="section_4 flex-col">
        <div class="group_4 flex-col justify-end">
          <div class="group_5 flex-row">
            <el-tabs v-model="query.category" @tab-click="loadData(true)">
              <el-tab-pane label="最新" name="最新"></el-tab-pane>
              <el-tab-pane label="同城" name="同城"></el-tab-pane>
              <el-tab-pane label="推荐" name="推荐"></el-tab-pane>
            </el-tabs>
            <div class="group_6 flex-col">
              <el-row :gutter="1">
                <el-col :span="20">
                  <el-input v-model="query.keywords" style="width:100%" placeholder="请输入关键字" clearable
                    @change="loadData(true)"></el-input>
                </el-col>
                <el-col :span="4">
                  <el-button type="primary" size="medium" icon="el-icon-search" @click="loadData(true)"></el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="group_9 flex-col justify-between">
          <el-row>
            <el-col :span="8" v-for="(item, index) in list" :key="index">
              <div @click="loadWebsite(item.id)">
                <website-card :form-data="item"></website-card>
              </div>
            </el-col>
          </el-row>
        </div>
        <bottom-detector :loadingMore="loading" :noMore="list.length >= total" @arriveBottom="loadData" :distance="400" />
        <div class="group_36 flex-row">
          <div class="text-group_25 flex-col justify-between">
            <span class="text_38">云官网</span>
            <span class="text_39">数字化转型的重要平台</span>
          </div>
        </div>
        <el-dialog :visible.sync="visible" width="250" @close="close" :modal="false">
          <div>
            <div style="text-align: center;margin-bottom: 0.8rem;font-size: medium;">请打开微信扫描下方二维码访问云官网</div>
            <div style="text-align: center;"> <img :src="qcCodeURL" alt="请扫描小程序码访问" width="280" height="280" /></div>
          </div>
        </el-dialog>
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
import websiteCard from "./websiteCard.vue"
import api from "./api"
import bottomDetector from '@/views/components/bottomDetector/index.vue'
export default {
  components: {
    headerCom,
    fotter,
    websiteCard,
    bottomDetector
  },
  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      visible: false,
      qcCodeURL: '',
      query: {
        // 三取一
        category: "最新",
        keywords: "",
        total: 0,
        size: 18,
        pn: 1,
      }
    };
  },
  mounted() {
    this.loadData()
  },
  methods: {
    close() {
      this.visible = false
    },
    loadData(flg) {
      this.loading = true
      api.list(this.query).then((res) => {
        if (res.code === 200) {
          if (flg) {
            this.list = res.data.list
            this.total = res.data.total
            this.query.pn = 1
          } else {
            this.list = [...this.list, ...res.data.list]
            this.total = res.data.total
            this.query.pn++;
          }
          this.loading = false

        }
      })
    },
    loadWebsite(id) {
      console.log(id)
      api.getWebsiteQcCode(id).then((res) => {
        if (res.code === 200) {
          this.qcCodeURL = this.$url(res.data.relativeUrl)
          this.visible = true
        }
      })
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />