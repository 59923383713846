<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_1 flex-col">
        <headerCom fontColor="color:white" />
        <div class="image-wrapper_1 flex-row">
          <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="./assets/img/git_fk.png"
          />
        </div>
        <div
          class="section_1 flex-row"
          @click="$scrollToTop('content')"
          style="cursor: pointer"
        >
          <div class="image-wrapper_2 flex-col">
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/psli9e8aow0ydxqdrja9igeuilfch0h5rj285d0195-c358-431c-b3d2-9d6f22228e6f.png"
            />
          </div>
        </div>
        <div class="text-wrapper_2 flex-row">
          <span
            class="text_3"
            @click="$scrollToTop('content')"
            style="cursor: pointer"
            >立即探索</span
          >
        </div>
      </div>
      <div class="block_2 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_4">Platform&nbsp;advantages</span>
          <span class="text_5">平台优势</span>
        </div>
        <div class="group_1 flex-row">
          <div class="group_2 flex-col">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/ps63zazfn9esf3lhvesz78sdeust0w5h1ywff27d476-980b-4b58-8875-255a73892ad6.png"
            />
            <span class="text_6">综合性平台</span>
            <span class="text_7">
              该平台就是为电机系统企业提供一种完整的、从项目设计到运营管理，再到后续维护服务全周期解决方案。
            </span>
          </div>
          <div class="group_3 flex-col">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/pst35d7q3q9n38tk8jqenoe9e9oocbvhmmd53ad309-db21-4988-94ce-adf7a0e14ec8.png"
            />
            <span class="text_8">企业精准服务</span>
            <span class="text_9"
              >集结万名业内专家提供云咨询服务，制定技术、生产等环节专项解决方案</span
            >
          </div>
          <div class="group_4 flex-col">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/psetnitrx922pfsnjxhsz8bq98i56plq5a98a424476-fba0-4e43-b88d-85b840a13680.png"
            />
            <span class="text_10">闭环产业链</span>
            <span class="text_11">
              通过云集采、云商城和云物流等服务，可以打造行业闭环产业链，形成完整的设计、采购、生产、销售、售后等链条，更好地服务于客户的需求。
            </span>
          </div>
          <div class="group_5 flex-col">
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/pst9qdtsa1f5snyd2u3xh5eqp39eeclyx27993187-5fae-44d6-ba47-1907c0e17bb8.png"
            />
            <span class="text_12">全周期解决方案</span>
            <span class="text_13"
              >从项目设计开始到项目实施、企业运营管理、云商城和云服务等方方面面的全周期服务。</span
            >
          </div>
        </div>
        <div class="image-wrapper_3 flex-row">
          <!-- <img class="image_7" referrerpolicy="no-referrer" :src="item.lanhuimage0" v-for="(item, index) in loopData0"
            :key="index" /> -->
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/01.jpg"
          />
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/02.jpg"
          />
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/03.jpg"
          />
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/04.jpg"
          />
        </div>
      </div>
      <div id="content" class="block_3 flex-col">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_14">ABOUT&nbsp;US</span>
          <span class="text_15">关于我们</span>
        </div>
        <div class="text-wrapper_3">
          <span class="text_16">
            绿色电机系统云平台是一个集成了项目设计、企业运营管理、云商城和云服务等多个功能模块的
          </span>
          <span class="text_17">全流程电机系统解决方案的综合性平台</span>
          <span class="text_18">
            。通俗而言，该平台就是为电机系统企业提供一种完整的、从项目设计到运营管理，再到后续维护服务全周期解决方案。
          </span>
        </div>
        <span class="text_19">
          平台的设计目标是将不同环节的电机系统企业服务整合起来，从而实现更高效的生产和管理。首先，从项目设计角度，该平台提供了强大的项目研究和设计工具，包括云可研和云设计服务。用户可以通过这些工具，进行市场调研、竞争分析、资金预算和风险评估等方面的研究，并进行详细的设计和方案制定。
        </span>
        <img
          class="image_9"
          referrerpolicy="no-referrer"
          src="./assets/img/psg4mcwwvt1ttww4lv0tgqkd23hlqz07x2ric79c93d6-21bf-45a6-b4c2-81752591bac8.png"
        />
      </div>
      <fotter :showTag="true" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter,
  },
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/pscnru9uuv0hnav1wvxqj7h78tmptyvkmcb357a38a-b208-4d32-8cca-801356af5af9",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/pss1vgptgszakd1f0rrf3ahn83r8fkkqtmc2da6503-e70b-4a95-87a7-018dbfc29c78",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/ps0w9w5s15madi9vom3fzsal8qkdyh6u8j8pd7e557e9-f2c2-4e14-a248-891ff34b7e8b",
        },
        {
          lanhuimage0:
            "https://lanhu.oss-cn-beijing.aliyuncs.com/psqdm7ll3f34mlvaohszjtjot5u3lewbr6abae153-48cc-49d9-96d9-4fc63a510615",
        },
      ],
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />