<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="box_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云合作</span>
        <span class="text_4">强链互补，让天下没有难加工的订单</span>
        <div class="group_3 flex-row justify-between" @click="$scrollToTop('content')" style="cursor: pointer;">
          <div class="image-wrapper_1 flex-col">
            <img class="thumbnail_1" referrerpolicy="no-referrer"
              src="./assets/img/psmj75895mbkk7c9qyhbcma6blesla73gycd5215902-10ca-4def-9da2-5fc25b20f9ca.png" />
          </div>
          <span class="text_5">立即探索</span>
        </div>
      </div>
      <div id="content" class="box_2 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_6">Cloud&nbsp;Collaboration</span>
          <span class="text_7">云合作</span>
        </div>
        <div class="text-wrapper_1">
          <span class="text_8">
            企业通过绿色电机平台发布外协订单需求，成交后给每张图纸一个条形码，接单机械加工企业内部安装我们的生产管理系统，实现企业订单自动生成，内部订单的工序级的可追溯管理，线上线下生产数据同步，订单加工进度实时反馈，并通过中间件技术根据条形码的唯一标识上传到绿色电机平台云合作模块，
          </span>
          <span class="paragraph_1">
            实现了跨国界、跨行业、跨地域、跨企业的机械加工订单的全生命周期管理。
            <br />
          </span>
        </div>
        <img class="image_2" referrerpolicy="no-referrer"
          src="./assets/img/psvq3fkerd0ocmis9qwugbmfx80n2cfmklk32562645-57d7-412e-be44-dc307d68149c.png" />
        <div class="box_3 flex-col">
          <div class="group_4 flex-row">
            <div class="text-group_2 flex-col justify-between">
              <span class="text_9">Service&nbsp;advantages</span>
              <span class="text_10">服务优势</span>
            </div>
          </div>
          <div class="group_5 flex-row">
            <div class="box_4 flex-col">
              <div class="image-text_1 flex-col justify-between">
                <img class="image_3" referrerpolicy="no-referrer"
                  src="./assets/img/psy0r56wxgaj9wb2r7viy2jvesvb5k8mk7cd5b3a3-09d3-491e-a469-e67e798a5f98.png" />
                <span class="text-group_3">简化合作流程</span>
              </div>
              <span class="text_11">
                云合作可以一站式地发布生产订单并连接外部供应商，避免了繁琐的邮件沟通和管理问题。
              </span>
            </div>
            <div class="box_5 flex-col">
              <div class="image-text_2 flex-col justify-between">
                <img class="image_4" referrerpolicy="no-referrer"
                  src="./assets/img/psj8np4xdr71m5g6oq4h3epletsyfjkc9c7df9659b-c655-45c9-8c43-551fede23082.png" />
                <span class="text-group_4">提高生产效率</span>
              </div>
              <span class="text_12">
                云合作可以与更多的供应商进行合作，选择最优质和最经济的方案，以提高生产效率和降低成本。
              </span>
            </div>
            <div class="box_6 flex-col">
              <div class="image-text_3 flex-col justify-between">
                <img class="image_5" referrerpolicy="no-referrer"
                  src="./assets/img/ps3ent9y4re6affmcuy4ky5rtn0tzvvcibccb61e0d8-2a60-4c0a-a9d8-de5246343bb9.png" />
                <span class="text-group_5">加强数据分析</span>
              </div>
              <span class="text_13">
                云合作提供了实时的生产数据和基于历史数据的分析报告，帮助您更深入地了解您的生产过程和供应链。
              </span>
            </div>
          </div>
        </div>
        <fotter :showTag="true" />
      </div>
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter
  },
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />