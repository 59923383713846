var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page flex-col"},[_c('div',{staticClass:"section_1 flex-col"},[_c('div',{staticClass:"box_1 flex-col"},[_c('headerCom',{attrs:{"fontColor":"color:white"}}),_c('span',{staticClass:"text_3"},[_vm._v("云服务")]),_vm._m(0),_c('div',{staticClass:"image-text_1 flex-row justify-between",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$scrollToTop('content')}}},[_c('img',{staticClass:"label_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psvw56hcv9n6iaw159z3t9oo5p2rbebmx3d44ae287d-f980-4cc2-b24f-9756029647a1.png")}}),_c('span',{staticClass:"text-group_1"},[_vm._v("立即探索")])])],1),_c('div',{staticClass:"box_4 flex-col"},[_vm._m(1),_c('span',{staticClass:"text_6"},[_vm._v("工业互联网平台云服务模块可以为工业企业提供高效、稳定、安全的工业互联网服务。")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"section_12 flex-col",attrs:{"id":"content"}},[_vm._m(7),_c('span',{staticClass:"text_52"},[_vm._v(" 工业互联网平台云服务模块是一个集成了多个云服务功能的平台，可以提供综合性的解决方案来解决工业企业的各种问题。 ")]),_c('div',{staticClass:"group_10 flex-row"},[_c('partBtn')],1)])]),_c('fotter',{attrs:{"showTag":true}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"paragraph_1"},[_vm._v(" 集结万名业内专家提供云咨询服务，制定技术、生产等环节专项解决方案， "),_c('br'),_vm._v(" 建立区域维修中心、对企业做好精准服务 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_2 flex-col justify-between"},[_c('span',{staticClass:"text_4"},[_vm._v("Main Services")]),_c('span',{staticClass:"text_5"},[_vm._v("主要服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_3 flex-row justify-between"},[_c('div',{staticClass:"group_1 flex-col"},[_c('div',{staticClass:"block_1 flex-col"}),_c('span',{staticClass:"text_7"},[_vm._v("设备运行状态监测")]),_c('span',{staticClass:"text_8"},[_vm._v(" 通过物联网技术和传感器设备，实时监测设备的运行状态，及时发现设备故障，并向售后服务人员发送通知。 ")]),_c('span',{staticClass:"text_9"},[_vm._v("Equipment operation status monitoring")]),_c('span',{staticClass:"text_10"},[_vm._v("01")])]),_c('div',{staticClass:"group_2 flex-col"},[_c('div',{staticClass:"group_3 flex-col"}),_c('span',{staticClass:"text_11"},[_vm._v("设备数据采集服务")]),_c('span',{staticClass:"text_12"},[_vm._v(" 平台可以采集和上传设备的实时数据和运行状态，这些数据是实现设备智能监控及预测性维护的基础。 ")]),_c('span',{staticClass:"text_13"},[_vm._v("Device Data Collection Service")]),_c('span',{staticClass:"text_14"},[_vm._v("02")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_4 flex-row justify-between"},[_c('div',{staticClass:"group_4 flex-col"},[_c('div',{staticClass:"section_5 flex-col"}),_c('span',{staticClass:"text_15"},[_vm._v("数据存储服务")]),_c('span',{staticClass:"text_16"},[_vm._v(" 平台可以提供安全稳定的云端存储服务，永久性存储设备的历史数据。用户可以根据需求选择不同的存储方案，包括按时间段存储、按设备类型存储等。 ")]),_c('span',{staticClass:"text_17"},[_vm._v("Data Storage Services")]),_c('span',{staticClass:"text_18"},[_vm._v("03")])]),_c('div',{staticClass:"group_5 flex-col"},[_c('div',{staticClass:"section_6 flex-col"}),_c('span',{staticClass:"text_19"},[_vm._v("数据分析服务")]),_c('div',{staticClass:"text-group_3 flex-col justify-between"},[_c('span',{staticClass:"text_20"},[_vm._v(" 平台可以通过对设备数据的分析，预判未来可能出现的故障，并提供相应的预警和维护方案。同时，也可以借助数据挖掘技术对设备产生的数据进行实时处理和分析，以创建更高效的生产环境和运营流程。 ")]),_c('span',{staticClass:"text_21"},[_vm._v("Data Analysis Services")])]),_c('span',{staticClass:"text_22"},[_vm._v("04")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_7 flex-row justify-between"},[_c('div',{staticClass:"group_6 flex-col"},[_c('div',{staticClass:"group_7 flex-col"}),_c('span',{staticClass:"text_23"},[_vm._v("远程服务支持")]),_c('span',{staticClass:"text_24"},[_vm._v(" 基于云服务技术，售后服务人员可以通过在平台上建立的远程服务连接，实现对设备的远程监管、故障分析和处理等操作。 ")]),_c('span',{staticClass:"text_25"},[_vm._v("Remote service support")]),_c('span',{staticClass:"text_26"},[_vm._v("05")])]),_c('div',{staticClass:"group_8 flex-col"},[_c('div',{staticClass:"box_5 flex-col"}),_c('span',{staticClass:"text_27"},[_vm._v("售后服务流程管控")]),_c('span',{staticClass:"text_28"},[_vm._v(" 平台可以对售后服务流程进行全面管控，包括客户服务请求、服务任务派发、服务人员调度、服务现场管理等，确保售后服务流程的快速和高效运转。 ")]),_c('span',{staticClass:"text_29"},[_vm._v("After sales service process control")]),_c('span',{staticClass:"text_30"},[_vm._v("06")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_8 flex-row justify-between"},[_c('div',{staticClass:"block_2 flex-col"},[_c('div',{staticClass:"box_6 flex-col"}),_c('span',{staticClass:"text_31"},[_vm._v("知识库管理和分享")]),_c('span',{staticClass:"text_32"},[_vm._v(" 平台通过搭建知识库，收集、整理和分享针对设备售后服务的技术资料、维修手册、应急处理流程等，帮助售后服务人员快速解决故障问题。 ")]),_c('span',{staticClass:"text_33"},[_vm._v("Knowledge base management and sharing")]),_c('span',{staticClass:"text_34"},[_vm._v("07")])]),_c('div',{staticClass:"block_3 flex-col"},[_c('div',{staticClass:"section_9 flex-col"}),_c('span',{staticClass:"text_35"},[_vm._v("维护管理服务")]),_c('span',{staticClass:"text_36"},[_vm._v(" 平台可针对设备监控数据和分析结果，提供定制化的设备维护管理服务，并为用户提供基于维修服务的云平台，实现设备维修的无纸化管理。该系统可自动分配维护任务给指定的维护人员，也可以自动处理客户请求的维修服务。 ")]),_c('span',{staticClass:"text_37"},[_vm._v("Maintenance and management services")]),_c('span',{staticClass:"text_38"},[_vm._v("08")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_10 flex-row justify-between"},[_c('div',{staticClass:"box_7 flex-col"},[_c('div',{staticClass:"group_9 flex-col"}),_c('span',{staticClass:"text_39"},[_vm._v("应用开发服务")]),_c('div',{staticClass:"text-group_4 flex-col justify-between"},[_c('span',{staticClass:"text_40"},[_vm._v(" 平台可以对多种下层设备进行无缝连接，将它们集成到云端，支持第三方应用或系统的开发和应用集成，以满足不同企业的需求。同时，也可支持应用开发者快速开发基于云的应用，简化应用程序的实现和管理。 ")]),_c('span',{staticClass:"text_41"},[_vm._v("Application development services")])]),_c('span',{staticClass:"text_42"},[_vm._v("09")])]),_c('div',{staticClass:"box_8 flex-col"},[_c('div',{staticClass:"block_4 flex-col"}),_c('span',{staticClass:"text_43"},[_vm._v("安全保护服务")]),_c('span',{staticClass:"text_44"},[_vm._v(" 平台可以提供强大的安全保护策略，包括密钥管理、数据加密、网络安全等多重安全措施。同时，提供可靠的备份和灾难恢复计划，确保数据安全。 ")]),_c('span',{staticClass:"text_45"},[_vm._v("Security protection services")]),_c('span',{staticClass:"text_46"},[_vm._v("10")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-group_6 flex-col justify-between"},[_c('span',{staticClass:"text_50"},[_vm._v("Cloud Services")]),_c('span',{staticClass:"text_51"},[_vm._v("云服务")])])
}]

export { render, staticRenderFns }