<template>
  <div class="hb4_main">
    <!-- <span class="text_3">云商城</span>
    <span class="text_4">提高企业生产效率和管理水平&nbsp;创造更多的商业机会和价值空间</span> -->

    <div class="text-wrapper_7 flex-row">
      <span class="text_15">云商城</span>
    </div>
    <div class="group_7 flex-row justify-between">
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="./assets/img/psehx24x56l5vk5yzzybgsb0ai9bv3ver90b7815b-dd99-4985-a3ad-e7aed0d55c4a.png"
      />
      <span class="text_16">•&nbsp;绿色电机系统云平台</span>
    </div>
    <div class="group_7 flex-row justify-between">
      <span class="text_17"
        >提高企业效果和管理水平 &nbsp;创造更多的商业机会和价值空间</span
      >
    </div>

    <a href="/lanhu_yunshangcheng">
      <img
        class="label_1"
        referrerpolicy="no-referrer"
        src="./assets/img/pshpeqdv7jetpaig6nd1lltjh4e0xfmx3584accf186-0079-490a-a80b-34113eb2e33b.png"
      />
    </a>

    <div class="hb4_mainDyn">
      <div class="hb4_pic0" id="hb4_pic0" @click="clcikFun(0, true)">
        <div class="hb4_pics_1" id="hb4_pic0_1">
          <div>云超市</div>
          <div>电机配件和成品展示</div>
        </div>
        <div class="hb4_pics_2" id="hb4_pic0_2">
          <div
            style="
              color: #010101;
              font-size: 0.64rem;
              margin: 1.654rem 0 0 1.36rem;
            "
          >
            云超市
          </div>
          <div
            style="
              color: #87899f;
              font-size: 0.426rem;
              margin: 1rem 0 0 1.36rem;
            "
          >
            电机配件和成品展示
          </div>
          <div style="margin: 0.5rem 0 0 1.36rem">
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/ps1ydgbpwdi2gfpng52a2r7op4v0na2j4e9fc80c60-4654-4093-b439-bb66203d1ba4.png"
              style="margin-top: 0.054rem"
            />
            <span
              style="
                color: #434343;
                font-size: 0.426rem;
                margin: 0 0.5rem 0 0.2rem;
              "
              >新能源专区</span
            >
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/ps1ydgbpwdi2gfpng52a2r7op4v0na2j4e9fc80c60-4654-4093-b439-bb66203d1ba4.png"
              style="margin-top: 0.054rem"
            />
            <span
              style="
                color: #434343;
                font-size: 0.426rem;
                margin: 0 0.5rem 0 0.2rem;
              "
              >电工材料专区</span
            >
          </div>

          <div style="margin: 0 0 0 1.36rem">
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/ps1ydgbpwdi2gfpng52a2r7op4v0na2j4e9fc80c60-4654-4093-b439-bb66203d1ba4.png"
              style="margin-top: 0.054rem"
            />
            <span
              style="
                color: #434343;
                font-size: 0.426rem;
                margin: 0 0.96rem 0 0.2rem;
              "
              >外贸专区</span
            >
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/ps1ydgbpwdi2gfpng52a2r7op4v0na2j4e9fc80c60-4654-4093-b439-bb66203d1ba4.png"
              style="margin-top: 0.054rem"
            />
            <span
              style="
                color: #434343;
                font-size: 0.426rem;
                margin: 0 0.5rem 0 0.2rem;
              "
              >电机行业专区</span
            >
          </div>

          <div style="margin: 0 0 0 1.36rem">
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/ps1ydgbpwdi2gfpng52a2r7op4v0na2j4e9fc80c60-4654-4093-b439-bb66203d1ba4.png"
              style="margin-top: 0.054rem"
            />
            <span
              style="
                color: #434343;
                font-size: 0.426rem;
                margin: 0 0.5rem 0 0.2rem;
              "
              >海外资源专区</span
            >
          </div>

          <div style="margin: 0 0 0 1.36rem">
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/ps1ydgbpwdi2gfpng52a2r7op4v0na2j4e9fc80c60-4654-4093-b439-bb66203d1ba4.png"
              style="margin-top: 0.054rem"
            />
            <span
              style="
                color: #434343;
                font-size: 0.426rem;
                margin: 0 0.5rem 0 0.2rem;
              "
              >工业汽车专区</span
            >
          </div>

          <div style="position: absolute; bottom: 2rem; left: 1.36rem">
            <button
              style="
                background-color: #0a6df3;
                height: 1.094rem;
                width: 4.827rem;
                color: white;
                font-size: 0.426rem;
              "
              @click="to('/lanhu_yunchaoshi')"
            >
              立即查看
            </button>
          </div>
        </div>
      </div>
      <div class="hb4_pic1" id="hb4_pic1" @click="clcikFun(1, true)">
        <div class="hb4_pics_1" id="hb4_pic1_1">
          <div>云集采</div>
          <div>设备、原材料采集中心</div>
        </div>
        <div class="hb4_pics_2" id="hb4_pic1_2">
          <div
            style="
              color: #010101;
              font-size: 0.64rem;
              margin: 1.654rem 0 0 1.36rem;
            "
          >
            云集采
          </div>
          <div
            style="
              color: #87899f;
              font-size: 0.426rem;
              margin: 1rem 0 0 1.36rem;
            "
          >
            设备、原材料采集中心
          </div>

          <div style="position: absolute; bottom: 2rem; left: 1.36rem">
            <button
              style="
                background-color: #0a6df3;
                height: 1.094rem;
                width: 4.827rem;
                color: white;
                font-size: 0.426rem;
              "
              @click="to('/lanhu_yunjicai')"
            >
              立即查看
            </button>
          </div>
        </div>
      </div>
      <div class="hb4_pic2" id="hb4_pic2" @click="clcikFun(2, true)">
        <div class="hb4_pics_1" id="hb4_pic2_1">
          <div>云供求</div>
          <div>供求资源信息共享</div>
        </div>
        <div class="hb4_pics_2" id="hb4_pic2_2">
          <div
            style="
              color: #010101;
              font-size: 0.64rem;
              margin: 1.654rem 0 0 1.36rem;
            "
          >
            云供求
          </div>
          <div
            style="
              color: #87899f;
              font-size: 0.426rem;
              margin: 1rem 0 0 1.36rem;
            "
          >
            供求资源信息共享
          </div>

          <div style="position: absolute; bottom: 2rem; left: 1.36rem">
            <button
              style="
                background-color: #0a6df3;
                height: 1.094rem;
                width: 4.827rem;
                color: white;
                font-size: 0.426rem;
              "
              @click="to('/lanhu_yungongqiu')"
            >
              立即查看
            </button>
          </div>
        </div>
      </div>
      <div class="hb4_pic3" id="hb4_pic3" @click="clcikFun(3, true)">
        <div class="hb4_pics_1" id="hb4_pic3_1">
          <div>云展厅</div>
          <div>全息技术沉浸式展示产品, 形成云目录实现产品目录营销</div>
        </div>
        <div class="hb4_pics_2" id="hb4_pic3_2">
          <div
            style="
              color: #010101;
              font-size: 0.64rem;
              margin: 1.654rem 0 0 1.36rem;
            "
          >
            云展厅
          </div>
          <div
            style="
              color: #87899f;
              font-size: 0.426rem;
              margin: 1rem 0 0 1.36rem;
            "
          >
            全息技术沉浸式展示产品, 形成云目录实现产品目录营销
          </div>

          <div style="position: absolute; bottom: 2rem; left: 1.36rem">
            <button
              style="
                background-color: #0a6df3;
                height: 1.094rem;
                width: 4.827rem;
                color: white;
                font-size: 0.426rem;
              "
              @click="to('/lanhu_yunzhanting')"
            >
              立即查看
            </button>
          </div>
        </div>
      </div>
      <div class="hb4_pic4" id="hb4_pic4" @click="clcikFun(4, true)">
        <div class="hb4_pics_1" id="hb4_pic4_1">
          <div>云物流</div>
          <div>
            与央企物流集团合作，<br />
            利用其全国中心仓和城市,<br />分仓为企业提供,<br />及时、
            高效、优质服务
          </div>
        </div>
        <div class="hb4_pics_2" id="hb4_pic4_2">
          <div
            style="
              color: #010101;
              font-size: 0.64rem;
              margin: 1.654rem 0 0 1.36rem;
            "
          >
            云物流
          </div>
          <div
            style="
              color: #87899f;
              font-size: 0.426rem;
              margin: 1rem 0 0 1.36rem;
            "
          >
            与央企物流集团合作,利用其全国中心仓和城市,<br />
            分仓为企业提供,<br />及时、高效、优质服务
          </div>

          <div style="position: absolute; bottom: 2rem; left: 1.36rem">
            <button
              style="
                background-color: #0a6df3;
                height: 1.094rem;
                width: 4.827rem;
                color: white;
                font-size: 0.426rem;
              "
              @click="to('/lanhu_yunwuliu')"
            >
              立即查看
            </button>
          </div>
        </div>
      </div>
      <div class="hb4_pic5" id="hb4_pic5" @click="clcikFun(5, true)">
        <div class="hb4_pics_1" id="hb4_pic5_1">
          <div>云签约</div>
          <div>
            基于互联网技术的在线签约平台，<br />
            可以提供高 效、安全、便捷的签约服务
          </div>
        </div>
        <div class="hb4_pics_2" id="hb4_pic5_2">
          <div
            style="
              color: #010101;
              font-size: 0.64rem;
              margin: 1.654rem 0 0 1.36rem;
            "
          >
            云签约
          </div>
          <div
            style="
              color: #87899f;
              font-size: 0.426rem;
              margin: 1rem 0 0 1.36rem;
            "
          >
            基于互联网技术的在线签约平台，<br />
            可以提供高 效、安全、便捷的签约服务
          </div>

          <div style="position: absolute; bottom: 2rem; left: 1.36rem">
            <button
              style="
                background-color: #0a6df3;
                height: 1.094rem;
                width: 4.827rem;
                color: white;
                font-size: 0.426rem;
              "
              @click="to('/lanhu_yunqianyue')"
            >
              立即查看
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="css" src="./assets/index.rem.css" />
<style scoped>
.hb4_main {
  width: 100%;
  height: 23.53rem;
}

.hb4_mainDyn {
  /* border-color: aliceblue; */
  width: 41.5rem;
  height: 13.8rem;
  /* background-color: aqua; */
  margin: 1.3rem auto;

  overflow-x: hidden;
  overflow-y: hidden;
  overflow-wrap: normal;
}

.hb4_pic0 {
  position: relative;
  display: inline-block;
  width: 30%;
  height: 100%;
  cursor: pointer;
  /* background-color: darkcyan; */
}

.hb4_pic1 {
  position: relative;
  display: inline-block;
  width: 14%;
  height: 100%;
  cursor: pointer;
  /* background-color: azure; */
}

.hb4_pic2 {
  position: relative;
  display: inline-block;
  width: 14%;
  height: 100%;
  cursor: pointer;
  /* background-color: blanchedalmond; */
}

.hb4_pic3 {
  position: relative;
  display: inline-block;
  width: 14%;
  height: 100%;
  cursor: pointer;
  /* background-color: coral; */
}

.hb4_pic4 {
  position: relative;
  display: inline-block;
  width: 14%;
  height: 100%;
  cursor: pointer;
  /* background-color: chocolate; */
}

.hb4_pic5 {
  position: relative;
  display: inline-block;
  width: 14%;
  height: 100%;
  cursor: pointer;
  /* background-color: darkorange; */
}

.hb4_pics_1 {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  /* transition: opacity 1s ease-in-out; */
  /* background-color: lightcoral; */
  color: white;
  overflow-y: hidden;
}

.hb4_pics_1 div:nth-child(1) {
  font-size: 0.64rem;
  margin: 1.64rem 0 0 0.5rem;
}

.hb4_pics_1 div:nth-child(2) {
  font-size: 0.426rem;
  margin: 1rem 0 0 0.5rem;
  word-wrap: break-word;
}

.hb4_pics_2 {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  /* transition: opacity 1s ease-in-out; */
  /* background-color: greenyellow; */
  overflow-y: hidden;
}

#hb4_pic0_1 {
  background: url(./assets/loopImg/pic0.png);
}

#hb4_pic0_2 {
  background: url(./assets/loopImg/pic0_m.png);
}

#hb4_pic1_1 {
  background: url(./assets/loopImg/pic1.png);
}

#hb4_pic1_2 {
  background: url(./assets/loopImg/pic1_m.png);
}

#hb4_pic2_1 {
  background: url(./assets/loopImg/pic2.png);
}

#hb4_pic2_2 {
  background: url(./assets/loopImg/pic2_m.png);
}

#hb4_pic3_1 {
  background: url(./assets/loopImg/pic3.png);
}

#hb4_pic3_2 {
  background: url(./assets/loopImg/pic3_m.png);
}

#hb4_pic4_1 {
  background: url(./assets/loopImg/pic4.png);
}

#hb4_pic4_2 {
  background: url(./assets/loopImg/pic4_m.png);
}

#hb4_pic5_1 {
  background: url(./assets/loopImg/pic5.png);
}

#hb4_pic5_2 {
  background: url(./assets/loopImg/pic5_m.png);
}
</style>
<script>
import router from "@/router";
export default {
  data() {
    return {
      constants: {},

      autoLoop: true,

      index: 0,

      lock: 0,
    };
  },
  mounted() {
    this.clcikFun(this.index++, false);
    this.loopMove();
  },
  methods: {
    to(url) {
      router.push(url);
    },
    loopMove() {
      if (this.autoLoop) {
        this.clcikFun(this.index++, false);
        if (this.index > 5) {
          this.index = 0;
        }
      }
      setTimeout(() => {
        this.loopMove();
      }, 3000);
    },

    clcikFun(currentIndex, close) {
      if (this.lock == 0) {
        this.lock = 1;
        var that = this;
        // setTimeout(() => {
        //   this.clcikFunDelay(currentIndex, close);
        //   that.lock = 0;
        // });
        this.clcikFunDelay(currentIndex, close);
        that.lock = 0;
      }
    },
    clcikFunDelay(currentIndex, close) {
      if (close) {
        this.autoLoop = false;
        setTimeout(() => {
          this.autoLoop = true;
        }, 15000);
      }

      //   console.log(currentIndex);
      var targetArr = [
        "hb4_pic0",
        "hb4_pic1",
        "hb4_pic2",
        "hb4_pic3",
        "hb4_pic4",
        "hb4_pic5",
      ];
      var target = null;

      var maxIndex = 0;
      for (var i = 0; i < targetArr.length; i++) {
        target = document.getElementById(targetArr[i]);
        if (!target) {
          continue;
        }
        if (target.dataset.show == 1) {
          maxIndex = i;
          target.dataset.show = 0;
          break;
        }
      }

      // 得到目标
      target = document.getElementById(targetArr[maxIndex]);
      // 的哦到当前
      var current = document.getElementById(targetArr[currentIndex]);

      current.dataset.show = 1;

      // 内容动画
      // 先设置初始为，显示简要信息
      var cr1 = document.getElementById(targetArr[currentIndex] + "_1");
      var cr2 = document.getElementById(targetArr[currentIndex] + "_2");
      cr1.style.opacity = "1";
      cr2.style.opacity = "0";
      // cr1.animate([
      //     { opacity: '1' },],
      //     1000);

      // cr2.animate([
      //     { opacity: '0' },],
      //     1000);

      // setTimeout(() => {
      //     cr1.style.opacity = "1";
      //     cr2.style.opacity = "0";
      // }, 950);

      // 移动动画
      target.animate(
        [{ width: "30%" }, { width: "20%" }, { width: "14%" }],
        1000
      );

      current.animate(
        [{ width: "14%" }, { width: "20%" }, { width: "30%" }],
        1000
      );

      setTimeout(() => {
        target.style.width = "14%";
        current.style.width = "30%";
      }, 950);

      // 内容动画 当前内容显示详细
      cr1.animate([{ opacity: "0" }], 1000);
      cr2.animate([{ opacity: "1" }], 1000);

      setTimeout(() => {
        cr1.style.opacity = "0";
        cr2.style.opacity = "1";
      }, 950);

      // 自己点自己
      if (currentIndex == maxIndex) {
        return;
      }

      // 内容动画，显示详细的div改为像是简要
      //   console.log(targetArr[maxIndex] + "_1");
      var tr1 = document.getElementById(targetArr[maxIndex] + "_1");
      var tr2 = document.getElementById(targetArr[maxIndex] + "_2");

      tr1.animate([{ opacity: "1" }], 1000);

      tr2.animate([{ opacity: "0" }], 1000);

      setTimeout(() => {
        tr1.style.opacity = "1";
        tr2.style.opacity = "0";
      }, 950);
    },
  },
};
</script>