<template>
  <div style="z-index: 100">
    <div class="box_2_h flex-row">
      <img
        class="image_1_h"
        referrerpolicy="no-referrer"
        src="./assets/img/导航logo.png"
      />
      <div class="text_1_h">
        <a href="/">首页</a>
        <a href="#" @click="scrollTo('huaban3', '/lanhu_yungongchang')"
          >云工厂</a
        >
        <a href="#" @click="scrollTo('huaban4', '/lanhu_yunshangcheng')"
          >云商城</a
        >
        <a href="#" @click="scrollTo('huaban5', '/lanhu_yunfuwu')">云服务</a>
        <a href="#" @click="scrollTo('huaban2', '/lanhu_guanyuwomen')">关于我们</a>
      </div>
      <!--
      <span class="text_2">
        <a href="#">登录</a>
        |
        <a href="#" style="margin-left: 20px">注册</a>  
      </span>     
    --></div>
    <div class="block_3_h flex-col">
      <div class="index_h flex-col"></div>
      <div class="industry_h flex-col"></div>
      <div class="mall_h flex-col"></div>
      <div class="service_h flex-col"></div>
      <div class="aboutus_h flex-col"></div>
    </div>
  </div>
</template>
<script>
import router from "@/router";
export default {
  props: {
    fontColor: {
      type: String,
      default: "color:red",
    },
    anchor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      constants: {},
    };
  },
  methods: {
    scrollTo(id, path) {
      if (this.$route.path.includes("/lanhu_huaban1")) {
        this.$scrollToTop(id);
      } else {
        router.push({ path: path });
      }
    },
  },
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />