<template>
  <div class="page flex-col">
    <div class="box_1 flex-row">
      <div id="content" class="group_1 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_1">Cloud&nbsp;Factory</span>
          <span class="text_2">云工厂</span>
        </div>
        <div class="section_1 flex-row justify-between">
          <div class="section_2 flex-col">
            <div class="text-wrapper_1 flex-col">
              <span class="text_3">云官网</span>
            </div>
            <span class="text_4">服务项目介绍</span>
            <span class="paragraph_1">
              云官网是绿色电机行业数字化转型的重要平台。在这里，您可以获取、传递最新的行业资讯和技术动态，了解最新的行业动态，并参与到数字化转型的浪潮中来。
              <br />
              我们的使命是为电机行业提供更高效、更智能、更可持续的解决方案，帮助企业实现数字化转型，迎接未来挑战。
              <br />
            </span>
            <div class="text-wrapper_2 flex-col">
              <span class="text_5">
                <a href="/lanhu_yunguanwang" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
          <div class="section_3 flex-col">
            <div class="text-wrapper_3 flex-col">
              <span class="text_6">云名片</span>
            </div>
            <span class="text_7">服务项目介绍</span>
            <span class="paragraph_2">
              在当今社会，人们的社交圈越来越广泛，我们需要不断地结识新朋友、拓展人脉。而作为一个职场人士，更是需要时刻保持联系畅通，因此名片也成为了职场必备的工具之一。
              <br />
              云名片可以通过网络储存和分享，用户可以轻松地将自己的名片信息存储在云端，并通过二维码或链接与他人分享，避免了传统纸质名片的很多不便之处。
            </span>
            <div class="text-wrapper_4 flex-col">
              <span class="text_8">
                <a href="/lanhu_yunmingpian" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
        </div>
        <div class="section_4 flex-row justify-between">
          <div class="box_2 flex-col">
            <div class="text-wrapper_5 flex-col">
              <span class="text_9">云地图</span>
            </div>
            <span class="text_10">服务项目介绍</span>
            <span class="text_11">
              当今，随着工业制造行业的高速发展，云地图成为了一种不可或缺的工具。云地图是在线展示工业企业位置信息和企业信息的平台。它可以通过交互式地图技术来帮助用户获取关键性的数据和信息。
            </span>
            <div class="text-wrapper_6 flex-col">
              <span class="text_12">
                <a href="/lanhu_yunditu" style="color: white">立即了解</a></span
              >
            </div>
          </div>
          <div class="box_3 flex-col">
            <div class="text-wrapper_7 flex-col">
              <span class="text_13">云标识</span>
            </div>
            <span class="text_14">服务项目介绍</span>
            <span class="text_15">
              随着数字化时代的到来，企业之间和企业内部的信息交流变得越来越频繁和复杂。云标识是由工信部直属-中国信息通信研究院推出，支持兼容现有各种国际上主流的标识体系，提供公共查询入口和服务，是国家层面的标准化公共标识系统。真正实现产品上的一物一码。
            </span>
            <div class="text-wrapper_8 flex-col">
              <span class="text_16">
                <a href="/lanhu_yunbiaoshi" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
        </div>
        <div class="section_5 flex-row justify-between">
          <div class="block_1 flex-col">
            <div class="text-wrapper_9 flex-col">
              <span class="text_17">云创新联合体</span>
            </div>
            <span class="text_18">服务项目介绍</span>
            <span class="text_19">
              云创新联合体是绿色电机平台为业内精英提供的一个自主发展的平台。任何的业内精英都可以申请一个属于自己的创新联合体。&nbsp;云创新联合体不仅仅是一个整合资源的平台，也是一个自我宣传和展示的平台，企业主可以申请一个联合体，发布自己的产品信息、供求信息。同时，创建者可以将联合体中的信息推送至绿色电机平台，也可以将绿色电机平台的信息接入到联合体中。
            </span>
            <div class="text-wrapper_10 flex-col">
              <span class="text_20">
                <a href="/lanhu_yunchuangxinlianheti" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
          <div class="block_2 flex-col">
            <div class="text-wrapper_11 flex-col">
              <span class="text_21">云合作</span>
            </div>
            <span class="text_22">服务项目介绍</span>
            <span class="text_23">
              企业通过绿色电机平台发布外协订单需求，成交后给每张图纸一个条形码，接单机械加工企业内部安装我们的生产管理系统，实现企业订单自动生成，内部订单的工序级的可追溯管理，线上线下生产数据同步，订单加工进度实时反馈，并通过中间件技术根据条形码的唯一标识上传到绿色电机平台云合作模块，实现了跨国界、跨行业、跨地域、跨企业的机械加工订单的全生命周期管理。
            </span>
            <div class="text-wrapper_12 flex-col">
              <span class="text_24">
                <a href="/lanhu_yunhezuo" style="color: white"
                  >立即了解</a
                ></span
              >
            </div>
          </div>
        </div>
        <fotter :showTag="true" />
      </div>
      <div class="group_4 flex-col">
        <!-- <div class="block_5 flex-row">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/psta6wol8z6p7uqf1u7fgx8gur0kc1wops32ca9820-76ec-4950-aeb5-84b7e8c6bf81.png"
          />
          <span class="text_33">首页&nbsp;云工厂&nbsp;云商城&nbsp;云服务&nbsp;关于我们</span>
          <span class="text_34">登录&nbsp;&nbsp;|&nbsp;&nbsp;注册</span>
        </div> -->
        <headerCom />
        <!-- <div class="block_6 flex-col">
          <div class="section_8 flex-col"></div>
        </div> -->
        <span class="text_35">云工厂</span>
        <span class="paragraph_9">
          设备运营、产品制造、实时在线跟踪管理，搭平台、接应用、
          <br />
          建生态，实现企业产品全生命周期管理
        </span>
        <div
          class="block_7 flex-row justify-between"
          @click="$scrollToTop('content')"
        >
          <div class="image-wrapper_2 flex-col">
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/psmfgts8y7ail3bo9gq0yoyiff64a2uigbr32b5e924-c4f7-4710-b539-1cd31e8bc984.png"
            />
          </div>
          <span class="text_36">立即探索</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter,
  },
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />