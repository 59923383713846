<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="group_1 flex-col">
        <headerCom fontColor="color:white" />
        <span class="text_3">云设计</span>
        <span class="text_4"
          >产品&nbsp;工艺&nbsp;管理&nbsp;售后全方位运营链设计</span
        >
        <div
          class="box_3 flex-row justify-between"
          @click="$scrollToTop('content')"
          style="cursor: pointer"
        >
          <div class="image-wrapper_1 flex-col">
            <img
              class="thumbnail_1"
              referrerpolicy="no-referrer"
              src="./assets/img/ps29qm4p1kadfwxahsrdipxozdbo17snjyp95eaaeae-5b05-4064-bb29-9e162c6af354.png"
            />
          </div>
          <span class="text_5">立即探索</span>
        </div>
      </div>
      <div id="content" class="group_3 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_6">Main&nbsp;Services</span>
          <span class="text_7">主要服务</span>
        </div>
        <div class="box_4 flex-row justify-between">
          <div class="image-text_1 flex-row justify-between">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/psc3n6sxiq1g5vmqhr6l9fiohp4pq0njsye382884e-4295-45b8-9b58-613ac6b7d196.png"
            />
            <div class="text-group_2 flex-col justify-between">
              <span class="text_8">自动化的设计流程</span>
              <span class="text_9">
                通过绿色电机系统工业互联网平台的云设计板块，企业用户可以获得自动化、标准化的设计流程，从而大大减少人力成本和设计周期，提升设计效率。
              </span>
            </div>
          </div>
          <div class="image-text_2 flex-row justify-between">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/psmyq7hqmo52kxn55gwzir91tq6o9v7rd9f226e5b-1d43-4e66-b28d-6d45a18bee7e.png"
            />
            <div class="text-group_3 flex-col justify-between">
              <span class="text_10">全面的选型功能</span>
              <span class="text_11">
                绿色电机系统工业互联网平台针对不同项目的设备数据进行整理和分析，为用户提供全面的选型建议，保证设备的稳定性和可靠性。
              </span>
            </div>
          </div>
        </div>
        <div class="box_5 flex-row justify-between">
          <div class="image-text_3 flex-row justify-between">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/psdis2tb93pnf2hboyfihwgqrupsokxyihac64b278e-8ee6-494b-a4a5-4aca4e79ede6.png"
            />
            <div class="text-group_4 flex-col justify-between">
              <span class="text_12">性能分析和优化</span>
              <span class="text_13">
                通过绿色电机系统工业互联网平台提供的可视化分析工具，用户可以快速地分析项目所需设备的各项性能（如热力学性能、结构力学性能等），为用户提供优化建议，实现设计自动优化。
              </span>
            </div>
          </div>
          <div class="image-text_4 flex-row justify-between">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/pscha2gxjiefpayos2pos9kdhdubxcsmjrd93c21c1a-b5d1-42f7-a7c6-23124230cec9.png"
            />
            <div class="text-group_5 flex-col justify-between">
              <span class="text_14">智能化的文档管理</span>
              <span class="text_15">
                该板块提供智能化的文档管理功能，用户可以随时查看、编辑、共享和下载文档和图纸等资料，保证设计文件的安全和可追溯性。
              </span>
            </div>
          </div>
        </div>
        <div class="image-text_5 flex-row justify-between">
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="./assets/img/ps2vslt6wio9yb6dsr5tvx3b7p8108s12fl0f8c3c02-d8fd-4541-8120-442da7a6ee2a.png"
          />
          <div class="text-group_6 flex-col justify-between">
            <span class="text_16">项目云规划</span>
            <span class="text_17">
              除了项目设计外，平台还提供云规划功能，可以对项目进行全面规划和管理，包括预算管理、进度管理、资源管理等。
            </span>
          </div>
        </div>
        <div class="text-wrapper_1">
          <span class="paragraph_1">
            绿色电机系统工业互联网平台云设计板块是一个综合性、智能化的设计和管理服务解决方案。
            <br />
          </span>
          <span class="text_18">
            可以帮助企业提高工作效率、降低成本、提升产品品质，满足复杂项目的需求，带来更高的商业价值。
          </span>
        </div>
      </div>
      <div class="group_4 flex-col">
        <div class="text-group_7 flex-col justify-between">
          <span class="text_19">Cloud&nbsp;design</span>
          <span class="text_20">云设计</span>
        </div>
        <div class="text-wrapper_2">
          <span class="paragraph_2">
            针对特定项目的智能化设计服务解决方案
            <br />
          </span>
          <span class="text_21">
            通过该板块，企业用户可以在项目启动之初，便可获得智能化的设计支持，<br />大大缩短项目周期，提高生产效率和质量。
          </span>
        </div>
        <img
          class="image_8"
          referrerpolicy="no-referrer"
          src="./assets/img/ps6jz0pj28of671zsh2wjg55pkvnesjnwdj4400caf6-047b-496b-87dc-6e1fbcebb8d9.png"
        />
      </div>
      <fotter :showTag="true"  style="margin-top:8rem" />
    </div>
  </div>
</template>
<script>
import headerCom from "@/views/header/index.vue";
import fotter from "@/views/fotter/fotter.vue";
export default {
  components: {
    headerCom,
    fotter,
  },
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />